:root {
    --color-thread-line: #ff9900;
  
      /* Grids and gaps */
      --gap-default: 12px;
  
      --font-size: 15px;
  
      --size-avatar: 48px;
  }
  
  
  /* Hashtags */
  .hashtag.status-link {
    background-color: #898db84f;
    padding: 0px 5px;
    border-style: dashed;
    border-color: #CCC;
    border-width: 0.5px;
    border-radius: 6px;
    font-family: "mastodon-font-sans-serif",sans-serif;
  }
  
  .hashtag.status-link:hover {
    background-color: #cdd2f54f;
  }
  
  .detailed-status .hashtag.status-link {
    font-size: 20px;
  }
  
  .u-url.mention.status-link {
    background-color: #a7aeff4d;
    padding: 0px 3px;
    border-radius: 6px;
    font-family: "mastodon-font-sans-serif",sans-serif;
  }
  
  .u-url.mention.status-link:hover {
    background-color: #cdd2f54f;
  }
  
  .detailed-status .u-url.mention.status-link {
    font-size: 20px;
  }
  
  .u-url.mention.status-link {
    color: #d68100;
  }
  
  
  /* Link previews */
  .status-card.interactive,
  .status-card.compact.interactive {
    background-color: #191b22;
    border-radius: 10px;
    border: 1px solid #313543;
  }
  
  a.status-card,
  a.status-card.compact {
    background-color: #191b22;
    display: block;
    border-radius: 10px;
  }
  
  .fa-file-text::before {
    content: "";
  }
  
  .status-card.interactive .status-card__image-image,
  .status-card.compact.interactive .status-card__image-image,
  a.status-card .status-card__image-image,
  a.status-card .status-card__image-image.compact {
    height: 275.63px;
  }
  
  .status-card.interactive .status-card__content,
  .status-card.compact.interactive .status-card__content,
  a.status-card .status-card__content,
  a.status-card.compact .status-card__content {
    padding: 15px;
  }
  
  .status-card.interactive .status-card__title,
  .status-card.compact.interactive .status-card__title,
  a.status-card .status-card__title,
  a.status-card.compact .status-card__title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: normal;
    color: white;
    white-space: inherit;
  }
  
  .status-card.interactive .status-card__host,
  .status-card.compact.interactive .status-card__host,
  a.status-card .status-card__host,
  a.status-card.compact .status-card__host {
    font-size: 14px;
    color: #71767b;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .status-card.interactive .status-card__description,
  .status-card.compact.interactive .status-card__description,
  a.status-card .status-card__description,
  a.status-card.compact .status-card__description {
    color: #71767b;
  }
  
  .status-card__image-image,
  .status-card__image-preview {
    border-radius: 0px;
  }
  
  
  
  /* lanciano.it */
  
  .detailed-status__application {
        cursor: default;
        pointer-events: none;        
        text-decoration: none;
  }
  
  div.form-container:before{
  content: "Attenzione!";
  font-size: 24px;
  }
  
  form.new_user:before{
  content: "L'accesso è riservato agli utenti di Lanciano.it. Se riscontri problemi con l'accesso, prova ad ad effettuare il login sulla homepage di Lanciano.it";
  
  }
  
  .lanciano-emoji {
  vertical-align: middle;
  margin: -3px 0 0;
  }
  
  .display-name .display-name__html .lanciano-emoji, .status__display-name .lanciano-emoji {
  width: 20px;
  height: 20px;
  }
   
  svg.logo,
  .admin-wrapper .sidebar__toggle .logo {
  display: none;
  }
  
  
  .layout-single-column .status__thread .status__wrapper .status .status-card {
    margin-left: calc(var(--size-avatar) + var(--gap-default));
  }
  
  /* Status update tinted to right */
  .layout-single-column .status__thread .status__wrapper .status__action-bar,
  .layout-single-column .status__thread .status__wrapper .attachment-list,
  .layout-single-column .status__thread .status__wrapper .status__content__read-more-button,
  .layout-single-column .status__thread .status__wrapper .status .status__content,
  .layout-single-column .status__thread .status__wrapper .notification.notification-admin-sign-up .display-name__account {
    font-size: var(--font-size);
    padding-left: calc(var(--size-avatar) + var(--gap-default));
  }
  
  /* Translate link */
  .layout-single-column .translate ~ .status__content__read-more-button {
    padding-left: 0;
  }
  
  /* Media inside status update tinted to right */
  .layout-single-column .status__thread .status__wrapper .status .audio-player,
  .layout-single-column .status__thread .status__wrapper .status .video-player,
  .layout-single-column .status__thread .status__wrapper .status .media-gallery {
    margin-left: auto;
    /* Need to override inline styles */
    /* stylelint-disable-next-line */
    width: calc(100% - calc(var(--size-avatar) + var(--gap-default))) !important;
  }
  
  /* stylelint-disable selector-max-class, selector-max-combinators, selector-max-compound-selectors, selector-max-specificity, selector-max-pseudo-class, selector-not-notation */
  /* Threaded replies, see https://github.com/ronilaukkarinen/mastodon-bird-ui/issues/4 */
  .layout-single-column .scrollable > div > div .status.status-reply {
  border-color: transparent;
  position: relative;
  }
  
  /* If we don't have threads support yet, just use borders */
  .layout-single-column .scrollable > div:not(:has(.status__thread)) .status.status-reply {
  border-color: var(--color-border);
  }
  
  /* Always have border-bottom in threads on main level to separate the discussions */
  .layout-single-column .scrollable > div > div.status__thread,
  .layout-single-column .scrollable > div > div.status__thread:has(.status__thread) > div.status__thread:not(.status__thread--last-item),
  .layout-single-column .scrollable > div > div:not([class]) > div.status__thread {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: -1px;
  }
  
  .layout-single-column .scrollable > div > div.status__thread > div.status__thread:not(.status__thread--last-item):not(:has(.status__thread--last-item)) {
  border-bottom: 0;
  }
  
  /* If we don't have threads support yet, hide faux lines */
  .layout-single-column .scrollable > div:not(:has(.status__thread)) .status.status-reply .status__avatar::before {
  background-color: transparent;
  width: 0;
  }
  
  .layout-single-column .scrollable > div > .status__thread > .status__thread > div > div > .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread ~ .status__thread--first-item + .status__thread--last-item .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread.status__thread--last-item.status__thread--first-item .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread ~ .status__thread .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread.status__thread--first-item + .status__thread.status__thread--last-item .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread + .status__thread ~ .status__thread ~ .status__thread .status.status-reply .status__avatar::before {
  background-color: var(--color-thread-line);
  content: '';
  height: 100%;
  left: calc(calc(calc(var(--size-avatar) / 2) + 16px) - 1px);
  position: absolute;
  top: 24px;
  width: 2px;
  }
  
  /* Hide thread lines from the last items for at least 10 levels */
  .layout-single-column .scrollable > div > div.status__thread:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread:not(:has(.status__thread)).status__thread--last-item .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before,
  .layout-single-column .scrollable > div > .status__thread > .status__thread:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:has(.status__thread--last-item) > .status__thread--last-item:not(:has(.status__thread)) .status.status-reply .status__avatar::before {
  /* stylelint-disable-next-line */
  background-color: transparent !important;
  }
  
  .layout-single-column .scrollable > div > .status__thread.status__thread--first-item .status.status-reply .status__avatar::before {
  top: 60px;
  }